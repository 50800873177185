.top {

}
.bottom {

}
.tableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .table {
    max-width: 600px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  th {
    background-color: #f0f0f0;
  }
  
  th,
  td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  td {
    font-weight: 600;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  