.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.box {
  flex: 1 0 auto;
  margin: 1em;
  min-width: calc(33.33% - 2em);
}

.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.cardHeader {
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  padding: 1em;
  font-size: 1.25em;
  font-weight: bold;
}

.cardBody {
  padding: 1em;
  text-align: center;
}
