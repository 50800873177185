.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.box {
    flex: 1 0 auto;
    margin: 1em;
    min-width: calc(33.33% - 2em);
}

.card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    height: 100%;
}

.cardHeader {
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    padding: 1em;
    font-size: 1.25em;
    font-weight: bold;
}

.cardBody {
    padding: 1em;
    text-align: center;
}

.nftImg {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
}

.img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}


/* Add these styles to your existing CSS */

/* Container for the image and overlay */
.cardImageContainer {
    position: relative; /* Necessary for positioning the overlay */
    overflow: hidden; /* Hide any overflow of overlay content */
  }
  
  /* Overlay for the full-size image */
  .imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; /* Initially hidden */
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease; /* Transition effect for smooth visibility */
    cursor: pointer; /* Show pointer cursor on hover */
  }
  
  /* Styles for the full-size overlay image */
  .overlayImg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  /* Show the overlay when hovering over the container */
  .cardImageContainer:hover .imageOverlay {
    opacity: 1;
  }
  