.avaxType {
  color: #E84142;
  padding-top: 5px;
}

.nftImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  box-shadow: 2px 5px 5px grey;
 
}

.img {
  height: 20px;
  justify-content: center;
}
.flexContainer {
  /* We first create a flex layout context */
  display: inline-flex;
  background-color: #E84142;
  flex-direction: row;
  
  /* Then we define the flow direction 
      and if we allow the items to wrap 
    * Remember this is the same as:
    * flex-wrap: wrap;
    */
  flex-wrap: wrap;
  
  
  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
  box-shadow: 2px 5px 5px grey;
  border-radius: 10px;
 
  
  padding: 0;
  margin: 4;
  list-style: none;
}
.flexItem {
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 10px;
  
  padding: 5px;
  
  margin: 5px;
  line-height: 20px;

  font-weight: bold;
  font-size: 1em;
  text-align: center;
}
